export const endorsementsTableColumns = {
  formName: {
    formatDataKey: "Form Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  formNumber: {
    formatDataKey: "Form Number",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 150
  },
  formCode: {
    formatDataKey: "Form Code",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 150
  },
  formUsage: {
    formatDataKey: "Form Usage",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 150
  }
};
