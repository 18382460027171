var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Create New Endorsement',
    key: 'create'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"filters":_vm.filters,"hideAllFilters":false,"addAdvancedFilter":true,"element-loading-text":_vm.loadingText,"data":_vm.allEndorsements,"tableColumns":_vm.tableColumns,"hideToggleIcons":true,"totalRecords":_vm.getTotalRecords,"paginationLinks":_vm.getEndorsementLinks,"tableRowActions":_vm.tableRowActions,"showSelectable":true,"bulkMenuActions":_vm.bulkMenuActions,"tableActions":_vm.tableActions},on:{"fetch-query":_vm.fetchPageData,"tableRowClicked":_vm.viewCallback,"table-row-action":_vm.handleResponseAction,"bulk-action-event":_vm.bulkActionHandler,"selection-change":function($event){_vm.selectedDocuments = $event},"table-action":_vm.tableActionHandler}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Endorsements Found."},slot:"notFound"})],1),_c('ModalMessage',{attrs:{"name":_vm.activeModal,"icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"","description":_vm.modalDescription,"showClose":true,"buttons":[
      {
        text: `Continue`,
        classList: [''],
        click: () => _vm.doModalSuccessButtonAction()
      },
      {
        text: `Cancel`,
        classList: ['bg-red-700'],
        click: () => _vm.doModalCancelButtonAction()
      }
    ]}}),_c('ModalBase',{attrs:{"name":_vm.activeModalBase,"size":"medium","clickToClose":false,"showClose":true,"title":_vm.activeModalBaseTitle,"loading":_vm.makingApiRequest,"position":"center"}},[(_vm.activeModalBase === 'uploadEndorsementsImportFileModal')?_c('ImportsUploader',{attrs:{"defaultType":"endorsements","showHeading":false,"url":_vm.uploadUrl},on:{"close":function($event){return _vm.$modal.hide(_vm.activeModalBase)},"upload-success":_vm.fetchPageData}}):_vm._e(),(_vm.activeModalBase === 'endorsementAppliesToModal')?_c('div',[_c('SelectField',{ref:"field_company",attrs:{"label":_vm.__getText('companies', 'selectCompany'),"fixedLabels":true,"options":_vm.companyOptions,"required":true,"selected":_vm.companyNumber},on:{"change":_vm.handleCompaniesFieldChange}}),(_vm.companyNumber && _vm.selectedDocuments.length)?_c('IAButton',{staticClass:"float-right",attrs:{"click":_vm.applyEndorsementsToCompany,"disabled":Boolean(_vm.loadingText),"title":"Apply"}}):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }