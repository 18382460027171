
import {
  endorsementMapActions,
  endorsementMapGetters,
  endorsementMapState
} from "@/store/modules/endorsements";
import { ITableWrapperColumns } from "@/types";
import Vue from "vue";
import { endorsementsTableColumns } from "./endorsementsTableColumns";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { getCompaniesForFilter } from "@/helpers/companies";
import { ICompany } from "@/store/modules/companies/types";
import { IEndorsement } from "@/store/modules/endorsements/types";

interface IEndorsementData {
  errorMessage: string;
  successMessage: string;
  tableColumns: ITableWrapperColumns;
  isAdmin: boolean;
  filters: any[];
  tableRowActions: any[];
  endorsementId: string;
  bulkMenuActions: any[];
  selectedDocuments: any;
  tableActions: any[];
  uploadUrl: string;
  loadingText: string;
  companyNumber: number | null;
  activeModal: string;
  modalDescription: string;
  activeModalBase: string;
  activeModalBaseTitle: string;
}
export default Vue.extend({
  name: "Endorsements",
  components: {
    ImportsUploader: () =>
      import("@/views/admin/imports/ListImports/ImportsUploader.vue"),
    NoResultsFound
  },
  data(): IEndorsementData {
    return {
      successMessage: "",
      errorMessage: "",
      isAdmin: true,
      tableColumns: endorsementsTableColumns,
      filters: [
        {
          field: "formUsage",
          title: "Form Usage",
          options: [
            { value: "Mandatory", label: "Mandatory" },
            { value: "Optional", label: "Optional" }
          ]
        },
        {
          field: "appliesTo",
          title: "Line Of Business",
          options: [
            { value: "H", label: "Homeowner" },
            { value: "D", label: "Dwelling fire" },
            { value: "T", label: "Tenant" }
          ]
        },
        {
          field: "companies",
          title: "Company",
          options: getCompaniesForFilter()
        }
      ],
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      endorsementId: "",
      bulkMenuActions: [
        { title: "Delete Selected", disabled: false },
        { title: "Applies To", disabled: false }
      ],
      selectedDocuments: [],
      tableActions: ["Import Endorsements", "All Activities"].sort(),
      uploadUrl: "endorsements/bulk/upload",
      loadingText: "",
      companyNumber: null,
      activeModal: "",
      modalDescription: "",
      activeModalBase: "",
      activeModalBaseTitle: ""
    };
  },
  methods: {
    ...endorsementMapActions([
      "getAllEndorsements",
      "deleteEndorsement",
      "deleteBulkEndorsements",
      "updateEndorsement"
    ]),
    async doDeleteBulkEndorsement() {
      try {
        this.loadingText = "Deleting endorsements. Please wait...";
        const endorsementsToBeDeletedIds = this.selectedDocuments.map(
          (document: any) => document._id
        );
        await this.deleteBulkEndorsements(endorsementsToBeDeletedIds);
        this.$appNotifySuccess("Selected endorsements deleted");
        this.fetchPageData(
          this.$store.state.list_view_module["filterQuery"] || {}
        );
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.$modal.hide(this.activeModal);
      }
    },
    async applyEndorsementsToCompany() {
      try {
        this.loadingText = "Applying endorsement to companies";
        const endorsementIds = this.selectedDocuments.map(
          (endorsement: IEndorsement) => endorsement._id
        );

        await this.updateEndorsement({
          update: {
            companies: [this.companyNumber as number],
            endorsementIds,
            action: "appliesTo"
          }
        });

        const company = this.companyOptions.find(
          company => company.value === this.companyNumber
        );

        this.successMessage = `Applied endorsements to company ${company?.label}`;
        this.fetchPageData(
          this.$store.state.list_view_module["filterQuery"] || {}
        );
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient(error);
      } finally {
        this.$modal.hide(this.activeModalBase);
        this.companyNumber = null;
        this.loadingText = "";
      }
    },
    handleCompaniesFieldChange(value: number) {
      this.companyNumber = value;
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/admin/endorsements/create`).catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: Record<string, any>): Promise<any> {
      try {
        this.loadingText = "Loading endorsements. Please wait...";
        this.$emit("scrollTop");
        await this.getAllEndorsements({
          query: { ...queryObject }
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/endorsements/${_id}/edit`).catch(() => {});
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "edit":
          this.viewCallback(data);
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.endorsementId = id;
        this.activeModal = "singleEndorsementDeleteModal";
        this.modalDescription = "This will delete the endorsement. Continue?";
        this.$nextTick(() => {
          this.$modal.show(this.activeModal);
        });
      } catch (error) {
        if (error !== "cancel") {
          console.error(error.message);
        }
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOneEndorsement(): Promise<void> {
      try {
        this.loadingText = "Deleting endorsement. Please wait...";
        await this.deleteEndorsement(this.endorsementId);
        this.successMessage = "Endorsement deleted successfully";
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
        this.$modal.hide(this.activeModal);
      }
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Delete Selected": {
          this.activeModal = "bulkEndorsementDeleteModal";
          this.modalDescription =
            "This will delete the selected endorsements. Continue?";
          this.$nextTick(() => {
            this.$modal.show(this.activeModal);
          });
          break;
        }
        case "Applies To": {
          this.activeModalBase = "endorsementAppliesToModal";
          this.activeModalBaseTitle = "Endorsement Applies To";
          this.$nextTick(() => {
            this.$modal.show(this.activeModalBase);
          });
          break;
        }
        default:
          break;
      }
    },
    async doModalSuccessButtonAction() {
      if (this.activeModal === "bulkEndorsementDeleteModal") {
        await this.doDeleteBulkEndorsement();
      } else if (this.activeModal === "singleEndorsementDeleteModal") {
        await this.deleteOneEndorsement();
      } else {
        this.$appNotifyError("Invalid modal action");
      }
    },
    doModalCancelButtonAction() {
      this.$modal.hide(this.activeModal);
      this.activeModal = "";
    },
    tableActionHandler(action: string) {
      if (action === "Import Endorsements") {
        this.activeModalBase = "uploadEndorsementsImportFileModal";
        this.activeModalBaseTitle = "Upload Import File";
        this.$nextTick(() => {
          this.$modal.show(this.activeModalBase);
        });
      } else if (action === "All Activities") {
        this.$router.push(`/admin/endorsements/activities`).catch(() => {});
      }
    }
  },
  computed: {
    ...endorsementMapGetters(["getTotalRecords", "getEndorsementLinks"]),
    ...endorsementMapState(["makingApiRequest", "allEndorsements", "links"]),
    companyOptions(): { label: string; value: number }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: ICompany) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
    }
  }
});
